import React from 'react';
import AppLayout from '../../layout/app.layout';
import ListGroups from '../../components/content/list-groups.component';
import PAGE_TITLE_LIST from '../../config/page-title.config';

const ContentGroupsView = () => {
  const pageTitle = `${PAGE_TITLE_LIST.CONTENT_GROUP}`;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListGroups />
    </AppLayout>
  );
};

ContentGroupsView.propTypes = {};

export default ContentGroupsView;
