import React from 'react';
import AppLayout from '../../layout/app.layout';
import PAGE_TITLE_LIST from '../../config/page-title.config';
import ListCalendarEvents from '../../components/content/list-calendar-events.component';

const ContentCalendarEventsView = () => {
  const pageTitle = PAGE_TITLE_LIST.CONTENT_EVENTS;

  return (
    <AppLayout pageTitle={pageTitle}>
      <ListCalendarEvents />
    </AppLayout>
  );
};

export default ContentCalendarEventsView;
