import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { Box, IconButton, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@emotion/react';
import Picker from '@emoji-mart/react';
import Comments from './comments.component';
import Iconify from '../common/iconify.component';
import {
  checkNewCommentsByVideoId,
  deleteCommentByVideoId,
  getCommentsByVideoId,
  postCommentByVideoId,
} from '../../services/content.service';
import { maxLengthValidation } from '../../utils/validations.util';
import INTERVAL_FOR_CHECK_API from '../../config/module-configs/video-comments.config';

const CommentsConversation = ({ selectedVideo }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [comments, setComments] = useState([]);
  const [subscribers, setSubscribers] = useState([]);
  const [postNewComment, setPostNewComment] = useState('');
  const [isCommentPosted, setIsCommentPosted] = useState(true);
  const [loadMoreId, setLoadMoreId] = useState('');
  const [triggerClose, setTriggerClose] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const commentsRef = useRef([]);

  let interval;
  const {
    control,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    handleSubmit,
  } = useForm({
    defaultValues: {
      comment: '',
    },
  });

  const checkForNewComments = () => {
    if (
      commentsRef.current.length === 0 ||
      commentsRef.current[commentsRef.current.length - 1].type === 'c'
    ) {
      const lastCommentId =
        commentsRef.current.length === 0
          ? 0
          : commentsRef.current[commentsRef.current.length - 1].id;

      checkNewCommentsByVideoId(selectedVideo.videoId, lastCommentId)
        .then((res) => {
          if (res.data.newComments === true) {
            clearInterval(interval);

            const linkObj = {
              type: 'l',
              from: lastCommentId,
              to: 0,
              direction: 'next',
            };

            if (commentsRef.current.length === 0) {
              commentsRef.current = [];
              setComments([]);
            } else {
              setComments([...commentsRef.current, linkObj]);
              commentsRef.current = [...commentsRef.current, linkObj];
            }
          }
        })
        .catch(() => {});
    }
  };

  const getNewComments = (
    fromCommentId = 0,
    toCommentId = 0,
    direction = 'prev'
  ) => {
    let uri = `?videoId=${selectedVideo.videoId}`;
    uri += `&fromCommentId=${fromCommentId}`;
    uri += `&toCommentId=${toCommentId}`;
    uri += `&direction=${direction}`;

    if (Number(fromCommentId) !== 0 || Number(toCommentId) !== 0) {
      const linkId = `${direction}_${fromCommentId}_${toCommentId}`;
      setLoadMoreId(linkId);
    }

    getCommentsByVideoId(uri)
      .then((res) => {
        const addSubscribers = {};
        const data = [];

        Object.entries(res.data.subscribers).forEach(([key, value]) => {
          if (!subscribers[key]) {
            addSubscribers[key] = value;
          }
        });

        if (Object.keys(addSubscribers).length > 0) {
          setSubscribers({
            ...subscribers,
            ...addSubscribers,
          });
        }

        const linkObj = {
          type: 'l',
          from: 0,
          to: res.data.requested.to,
          direction: res.data.requested.direction,
        };

        if (
          res.data.loadMore &&
          res.data.requested.direction === 'prev' &&
          res.data.comments.length > 0
        ) {
          linkObj.from = res.data.comments[0].id;

          data.push(linkObj);
        }

        res.data.comments.map((el) =>
          data.push({
            type: 'c',
            id: el.id,
            comment: el.comment,
            isAdmin: el.subscriberId === -1,
            createdAt: el.createdAt,
            subscriberId: el.subscriberId,
            firstName: res.data.subscribers[el.subscriberId].firstName,
            lastName: res.data.subscribers[el.subscriberId].lastName,
            profilePic: res.data.subscribers[el.subscriberId].profilePic,
          })
        );

        if (
          res.data.loadMore &&
          res.data.requested.direction === 'next' &&
          res.data.comments.length > 0
        ) {
          linkObj.from = res.data.comments[res.data.comments.length - 1].id;

          data.push(linkObj);
        }

        const index = comments.findIndex(
          (obj) =>
            obj.type === 'l' &&
            obj.direction === res.data.requested.direction &&
            Number(obj.from) === Number(res.data.requested.from) &&
            Number(obj.to) === Number(res.data.requested.to)
        );

        if (
          index > -1 &&
          comments[index + 1] &&
          comments[index + 1].type === 'c' &&
          res.data.requested.direction === 'next'
        ) {
          data[data.length - 1].to = comments[index + 1].id;
        }

        if (index === -1) {
          setComments([...data]);
          commentsRef.current = [...data];
        } else {
          let part1 = [];
          let part2 = [];

          if (index === 0) {
            part1 = [];
            part2 = comments.slice(index + 1);
          } else {
            part1 = comments.slice(0, index);
            part2 = comments.slice(index + 1);
          }

          commentsRef.current = [...part1, ...data, ...part2];
          setComments([...part1, ...data, ...part2]);
        }

        setLoading(false);
        setLoadMoreId('');

        setTimeout(() => {
          if (index === -1) {
            const element = window.document.querySelector('.scrollbar');
            element.scrollTop = element.scrollHeight;
          }
        }, 100);
      })
      .catch(() => {
        setLoading(false);
        setLoadMoreId('');
      });
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    setShowEmojiPicker(false);
    setIsCommentPosted(false);

    if (postNewComment.length > 0) {
      if (new Blob([postNewComment.trim()]).size > 1500) {
        setError('comment', {
          error: true,
          message: 'This field must be less than or equal to 1500 characters.',
        });
        setIsCommentPosted(true);

        return;
      }

      const params = {
        id: selectedVideo.videoId,
        comment: postNewComment.trim(),
      };

      let query = '?lastCommentId=';
      if (
        commentsRef.current.length > 0 &&
        commentsRef.current[commentsRef.current.length - 1].type === 'l'
      ) {
        query += -1;
      } else {
        const lastCommentId =
          commentsRef.current.length > 0
            ? commentsRef.current[commentsRef.current.length - 1].id
            : 0;
        query += `${lastCommentId}`;
      }

      postCommentByVideoId(params, query)
        .then((res) => {
          if (res.data.id) {
            setPostNewComment('');
            setValue('comment', '');

            if (!subscribers[res.data.subscriberId]) {
              const adminData = {};
              adminData[res.data.subscriberId] = {
                profilePic: res.data.profilePic,
                firstName: res.data.firstName,
                lastName: res.data.lastName,
              };
              setSubscribers({
                ...subscribers,
                ...adminData,
              });
            }

            const appendCommentData = [];

            if (res.data.loadMore) {
              const linkObj = {
                type: 'l',
                from: res.data.linkParams.from,
                to: res.data.linkParams.to,
                direction: res.data.linkParams.direction,
              };

              appendCommentData.push(linkObj);
            }

            appendCommentData.push({
              type: 'c',
              id: res.data.id,
              comment: res.data.comment,
              isAdmin: true,
              createdAt: res.data.createdAt,
              subscriberId: res.data.subscriberId,
              firstName: res.data.firstName,
              lastName: res.data.lastName,
              profilePic: res.data.profilePic,
            });

            const data = commentsRef.current;
            if (data.length > 0 && data[data.length - 1].type === 'l') {
              data[data.length - 1].to = res.data.id;
            }

            commentsRef.current = [...data, ...appendCommentData];
            setComments([...data, ...appendCommentData]);

            clearInterval(interval);
            interval = setInterval(() => {
              checkForNewComments();
            }, INTERVAL_FOR_CHECK_API);

            setTimeout(() => {
              const element = window.document.querySelector('.scrollbar');
              element.scrollTop = element.scrollHeight;
            }, 100);

            setIsCommentPosted(true);
          }
        })
        .catch((error) => {
          setError('comment', error.response.data);
          setIsCommentPosted(true);
        });
    }
  };

  const deleteComment = (commentId) => {
    setTriggerClose(true);

    const uri = `?videoId=${selectedVideo.videoId}`;
    deleteCommentByVideoId(commentId, uri)
      .then((res) => {
        if (res.data.success) {
          const indexVal = comments.findIndex((el) => el.id === commentId);

          const newComments = comments;
          const prevIndex = indexVal - 1;
          const nextIndex = indexVal + 1;

          if (
            prevIndex >= 0 &&
            typeof newComments[nextIndex] !== 'undefined' &&
            (newComments[prevIndex].type === 'l' ||
              newComments[nextIndex].type === 'l')
          ) {
            if (
              newComments[prevIndex].type === 'l' &&
              newComments[nextIndex].type === 'l'
            ) {
              if (
                newComments[prevIndex].direction === 'next' &&
                newComments[nextIndex].direction === 'next'
              ) {
                newComments[prevIndex].to = newComments[nextIndex].to;
              }
              if (
                newComments[prevIndex].direction === 'prev' &&
                newComments[nextIndex].direction === 'next'
              ) {
                newComments[prevIndex].from = newComments[nextIndex].from;
              }

              newComments.splice(indexVal, 2);
            } else if (newComments[prevIndex].type === 'l') {
              if (newComments[prevIndex].direction === 'next') {
                newComments[prevIndex].to = newComments[nextIndex].id;
              } else {
                newComments[prevIndex].from = newComments[nextIndex].id;
              }

              newComments.splice(indexVal, 1);
            } else if (newComments[nextIndex].type === 'l') {
              if (newComments[nextIndex].direction === 'next') {
                newComments[nextIndex].from = newComments[prevIndex].id;
              } else {
                newComments[nextIndex].to = newComments[prevIndex].id;
              }

              newComments.splice(indexVal, 1);
            }
          } else {
            newComments.splice(indexVal, 1);
          }

          setComments([...newComments]);
          commentsRef.current = [...newComments];
        }
      })
      .catch(() => {});
  };

  const selectEmoji = (e) => {
    if (isCommentPosted) {
      const codesArray = [];
      const sym = e.unified.split('-');

      sym.forEach((el) => codesArray.push(`0x${el}`));

      const emoji = String.fromCodePoint(...codesArray);
      const emojiMsg = postNewComment ? postNewComment + emoji : emoji;

      setPostNewComment(emojiMsg);
    }
  };

  useEffect(() => {
    clearInterval(interval);

    setLoading(true);

    setComments([]);
    commentsRef.current = [];
    setShowEmojiPicker(false);
    setSubscribers([]);

    getNewComments();

    interval = setInterval(() => {
      checkForNewComments();
    }, INTERVAL_FOR_CHECK_API);

    return () => clearInterval(interval);
  }, [selectedVideo.videoId]);

  return (
    <Stack height="100%" maxHeight="100vh" width="auto">
      <Box
        p={2}
        sx={{
          width: '100%',
          backgroundColor: '#F8FAFF',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%', height: '100%' }}
        >
          <Stack direction="row" alignItems="center" spacing={2}>
            {selectedVideo.thumbnail ? (
              <Box
                component="img"
                sx={{
                  objectFit: 'cover',
                  width: 64,
                  height: 36,
                  bgcolor: '#EDEDED',
                  borderRadius: '5px',
                }}
                alt={selectedVideo.title}
                src={selectedVideo.thumbnail}
              />
            ) : (
              <Box
                sx={{
                  width: 64,
                  height: 36,
                  bgcolor: '#808080',
                  borderRadius: '5px',
                }}
              />
            )}

            <Typography
              sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: '2',
                WebkitBoxOrient: 'vertical',
                fontSize: '14px',
              }}
            >
              {selectedVideo.title}
            </Typography>
          </Stack>

          {/* <Stack direction="row" alignItems="center" spacing={3}>
            <IconButton>
              <Iconify icon="fe:search" />
            </IconButton>
          </Stack> */}
        </Stack>
      </Box>

      <Box
        className="scrollbar"
        width="100%"
        sx={{
          flexGrow: 1,
          height: '100%',
          overflowY: 'auto',
          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
        }}
        onClick={() => setShowEmojiPicker(false)}
      >
        <Comments
          loading={loading}
          comments={comments}
          getNewComments={getNewComments}
          deleteComment={deleteComment}
          triggerClose={triggerClose}
          setTriggerClose={setTriggerClose}
          loadMoreId={loadMoreId}
        />
      </Box>

      {selectedVideo.comments ? (
        <Box
          p={2}
          sx={{
            width: '100%',
            backgroundColor:
              theme.palette.mode === 'light'
                ? '#F8FAFF'
                : theme.palette.background.paper,
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
          }}
        >
          <form
            id="add-edit-form"
            onSubmit={(e) => handleSubmit(onFormSubmit(e))}
          >
            <Stack direction="row" alignItems="center" spacing={3}>
              <Stack sx={{ width: '100%' }}>
                <Box
                  sx={{
                    display: 'none',
                    zIndex: 10,
                    position: 'fixed',
                    bottom: 81,
                    right: 100,
                  }}
                />
                <Controller
                  name="comment"
                  control={control}
                  rules={{ ...maxLengthValidation(1500) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Post your comment..."
                      placeholder="Post your comment..."
                      type="text"
                      value={postNewComment}
                      onChange={(e) => {
                        setPostNewComment(e.target.value);
                        clearErrors('comment');
                      }}
                      error={!!errors.comment}
                      helperText={errors?.comment?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 1500 }}
                      onClick={() => setShowEmojiPicker(false)}
                      disabled={!isCommentPosted || !selectedVideo.comments}
                    />
                  )}
                />
              </Stack>

              <Box
                sx={{
                  height: 20,
                  width: 50,
                  borderRadius: 1.5,
                }}
              >
                <Stack
                  sx={{
                    height: '100%',
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <IconButton
                    aria-label="emoji"
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  >
                    <Iconify
                      icon="entypo:emoji-happy"
                      sx={{ color: theme.palette.primary.main }}
                    />
                  </IconButton>
                  <IconButton type="submit">
                    <Iconify
                      icon="ph:paper-plane-tilt-bold"
                      sx={{ color: theme.palette.primary.main }}
                    />
                  </IconButton>

                  {showEmojiPicker && (
                    <Box sx={{ position: 'relative' }}>
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          transform: 'translate(-107.5%, -115%)',
                        }}
                      >
                        <Picker
                          onEmojiSelect={selectEmoji}
                          // onClickOutside={() => setShowEmojiPicker(false)}
                          emojiVersion={14}
                          emojiSize="20"
                          theme="auto"
                          perLine="10"
                          previewPosition="none"
                        />
                      </Box>
                    </Box>
                  )}
                </Stack>
              </Box>
            </Stack>
          </form>
        </Box>
      ) : null}
    </Stack>
  );
};

CommentsConversation.propTypes = {
  selectedVideo: PropTypes.object.isRequired,
};

export default CommentsConversation;
