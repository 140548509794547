import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import CountWidget from './count-widget.component';
import { getCounters } from '../../services/reports.service';
import { CONTENT_ALIAS } from '../../config/const.config';
import ROUTE_PATH from '../../config/routes.config';

const Counters = () => {
  const [counters, setCounters] = useState({
    groups: 0,
    categories: 0,
    videos: 0,
    subscribers: 0,
    sessions: 0,
    liveSessions: 0,
  });

  useEffect(() => {
    getCounters()
      .then((res) => {
        setCounters({
          ...counters,
          groups: res?.data?.groups || 0,
          categories: res?.data?.categories || 0,
          videos: res?.data?.videos || 0,
          subscribers: res?.data?.subscribers || 0,
          sessions: res?.data?.sessions || 0,
          liveSessions: res?.data?.liveSessions || 0,
        });
      })
      .catch(() => {
        // ignore
      });
  }, []);

  return (
    <Grid container spacing={3} mb={3}>
      <Grid item xs={12} sm={2} md={2}>
        <CountWidget
          title={`${CONTENT_ALIAS.GROUP_PLURAL}`}
          total={counters.groups}
          color="warning"
          icon="iconamoon:category"
          navLink={ROUTE_PATH.CONTENT_GROUPS}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2}>
        <CountWidget
          title={`${CONTENT_ALIAS.CATEGORY_PLURAL}`}
          total={counters.categories}
          color="info"
          icon="hugeicons:course"
          navLink={ROUTE_PATH.CONTENT_CATEGORIES}
        />
      </Grid>

      <Grid item xs={12} sm={2} md={2}>
        <CountWidget
          title={`${CONTENT_ALIAS.VIDEO_PLURAL}`}
          total={counters.videos}
          color="success"
          icon="octicon:video-16"
          navLink={ROUTE_PATH.CONTENT_VIDEOS}
        />
      </Grid>

      <Grid item xs={12} sm={2} md={2}>
        <CountWidget
          title="Subscribers"
          total={counters.subscribers}
          color="error"
          icon="ci:users"
        />
      </Grid>

      <Grid item xs={12} sm={2} md={2}>
        <CountWidget
          title="Watched Sessions"
          total={counters.sessions}
          color="warning"
          icon="ic:outline-list-alt"
          navLink={ROUTE_PATH.REPORTS_SESSIONS}
        />
      </Grid>

      <Grid item xs={12} sm={2} md={2}>
        <CountWidget
          title="Live Sessions"
          total={counters.liveSessions}
          color="info"
          icon="fluent:live-24-filled"
          navLink={ROUTE_PATH.REPORTS_LIVE_USERS}
        />
      </Grid>
    </Grid>
  );
};

export default Counters;
