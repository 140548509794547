import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, CONTENT_ALIAS, VIDEO_TYPES } from '../const.config';

// Videos module configurations
export const LISTING_COLUMNS = [
  {
    id: 'thumbnail',
    label: 'Image Preview',
    width: '5%',
    align: 'center',
    dataKey: 'thumbnail',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'title',
    label: 'Title',
    width: '15%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'preview',
    label: '',
    width: '2%',
    align: 'right',
    dataKey: 'preview',
    sortable: false,
    type: CELL_DATA_TYPES.PREVIEW,
  },
  {
    id: 'duration',
    label: 'Duration',
    width: '10%',
    align: 'left',
    dataKey: 'duration',
    sortable: true,
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'programName',
    label: `${CONTENT_ALIAS.CATEGORY_SINGULAR}`,
    width: '20%',
    align: 'left',
    dataKey: 'programName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'videoType',
    label: 'Video Type',
    width: '20%',
    align: 'left',
    dataKey: 'videoType',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.TRANSLATE.value,
    label: ACTIONS.TRANSLATE.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_MP4.value,
    label: ACTIONS.UPLOAD_MP4.label,
  },
  {
    action: ACTIONS.VIEW_VIDEO_COMMENTS.value,
    label: ACTIONS.VIEW_VIDEO_COMMENTS.label,
    conditional: (val) =>
      val.videoType !== VIDEO_TYPES[0] && val.videoType !== VIDEO_TYPES[3],
  },
  {
    action: ACTIONS.ASSIGN_CATEGORIES.value,
    label: ACTIONS.ASSIGN_CATEGORIES.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_VIDEOS_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'duration',
    label: 'Duration',
    dataKey: 'duration',
    type: CELL_DATA_TYPES.DURATION,
  },
  {
    id: 'categoryName',
    label: `${CONTENT_ALIAS.CATEGORY_SINGULAR}`,
    dataKey: 'categoryName',
    type: CELL_DATA_TYPES.TEXT,
    conditional: (val) =>
      val.videoType !== VIDEO_TYPES[0] && val.videoType !== VIDEO_TYPES[3],
  },
  {
    id: 'videoUrl',
    label: `${CONTENT_ALIAS.VIDEO_SINGULAR} URL`,
    dataKey: 'videoUrl',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'videoType',
    label: 'Video Type',
    dataKey: 'videoType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'transmissionOrgan',
    label: `${CONTENT_ALIAS.ASPECT_SINGULAR}`,
    dataKey: 'transmissionOrgan',
    type: CELL_DATA_TYPES.TEXT,
    conditional: (val) => val.videoType === VIDEO_TYPES[2],
  },
  {
    id: 'testimonialTabName',
    label: 'Testimonial Tab',
    dataKey: 'testimonialTabName',
    type: CELL_DATA_TYPES.TEXT,
    conditional: (val) => val.videoType === VIDEO_TYPES[3],
  },
  {
    id: 'testimonialCategoryName',
    label: 'Testimonial Category',
    dataKey: 'testimonialCategoryName',
    type: CELL_DATA_TYPES.TEXT,
    conditional: (val) => val.videoType === VIDEO_TYPES[3],
  },
  {
    id: 'thumbnail',
    label: 'Thumbnail',
    dataKey: 'thumbnail',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'comments',
    label: 'Comments',
    dataKey: 'comments',
    type: CELL_DATA_TYPES.BOOL,
    conditional: (val) =>
      val.videoType !== VIDEO_TYPES[0] && val.videoType !== VIDEO_TYPES[3],
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'cdnStatus',
    label: 'CDN Status',
    dataKey: 'cdnStatus',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const VIDEOJS_OPTIONS = {
  controls: true,
  preload: 'auto',
  responsive: true,
  controlBar: {
    pictureInPictureToggle: false,
  },
  sources: [
    {
      src: '',
    },
  ],
};
