import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewCategory,
  viewCategoryById,
  updateCategoryById,
} from '../../services/content.service';
import Status from '../form-elements/status.component';
import PaidType from '../form-elements/paid-type.component';
import { CONTENT_ALIAS } from '../../config/const.config';
import Groups from '../form-elements/groups.component';
import FeaturedType from '../form-elements/featured-type.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditCategories = ({ title, dataId, onCancel, onSuccess, options }) => {
  const {
    control,
    formState: { errors },
    watch,
    register,
    unregister,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      instructor: '',
      paidType: null,
      group: null,
      sku: '',
      purchaseURL: '',
      status: null,
      featured: null,
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [helperTextSKU, setHelperTextSKU] = useState(null);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });
  const watchPaid = watch('paidType');

  const saveNewData = (payload) => {
    createNewCategory(payload)
      .then(() => {
        onSuccess(`New ${CONTENT_ALIAS.CATEGORY_SINGULAR} added successfully.`);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateCategoryById(dataId, payload)
      .then(() => {
        onSuccess(
          `${CONTENT_ALIAS.CATEGORY_SINGULAR} details updated successfully.`
        );
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      name: data.name,
      instructor: data.instructor,
      isPaid: data.paidType,
      sku: data.sku,
      groupId: Number(data.group),
      isFeatured: Number(data.featured),
      purchaseURL: data.purchaseURL,
      description: data.description,
      status: data.status,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!watchPaid && watchPaid === 0) {
      unregister('purchaseURL');
      setValue('purchaseURL', '');
    } else if (watchPaid) {
      register('purchaseURL');
    }
  }, [watchPaid]);

  useEffect(() => {
    if (!dataId) {
      setHelperTextSKU("You won't be able to change SKU later on.");
      setLoading(false);
      return;
    }
    viewCategoryById(dataId)
      .then((res) => {
        // Fill form values
        setValue('name', res.data?.name || '');
        setValue('instructor', res.data?.instructor || '');
        setValue('sku', res.data?.sku || '');
        setValue('group', res.data?.groupId || '');
        setValue('paidType', res.data?.isPaid || 0);
        setValue('featured', res.data?.isFeatured || 0);
        setValue('purchaseURL', res.data?.purchaseURL || '');
        setValue('description', res.data?.description || '');
        setValue('status', res.data?.status || 0);

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${CONTENT_ALIAS.CATEGORY_SINGULAR} Name`}
                      type="text"
                      error={!!errors.name}
                      helperText={errors?.name?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(1500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 1500 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="instructor"
                  control={control}
                  rules={{
                    ...{ ...maxLengthValidation(500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Instructor"
                      type="text"
                      error={!!errors.instructor}
                      helperText={errors?.instructor?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 500 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="paidType"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <PaidType
                      id="paidType"
                      name="paidType"
                      label="Paid Type"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.paidType?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="group"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Groups
                      id="group"
                      name="group"
                      label={`Select ${CONTENT_ALIAS.GROUP_SINGULAR}`}
                      defaultValue={value}
                      onChange={onChange}
                      sx={{ width: '100%' }}
                      error={errors?.group?.message || ''}
                      options={options}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="sku"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(100) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label={`${CONTENT_ALIAS.CATEGORY_SINGULAR} SKU`}
                      type="text"
                      error={!!errors.sku}
                      helperText={errors?.sku?.message || helperTextSKU}
                      fullWidth
                      inputProps={{ maxLength: 100 }}
                      disabled={!!dataId}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="purchaseURL"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...(watchPaid && validationRules.REQUIRED),
                    ...{ ...maxLengthValidation(500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Purchase URL"
                      type="text"
                      error={!!errors.purchaseURL}
                      helperText={errors?.purchaseURL?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 500 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="sub_status"
                      name="sub_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="featured"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <FeaturedType
                      id="featured"
                      name="featured"
                      label="Featured"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.featured?.message || ''}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditCategories.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  options: PropTypes.objectOf.isRequired,
};

export default AddEditCategories;
