// List of validations
const rules = {
  REQUIRED: {
    required: {
      value: true,
      message: 'This field is required.',
    },
  },
  PASSWORD: {
    minLength: {
      value: 8,
      message: `The password must be 8 characters long.`,
    },
  },
  REQUIRED_EMAIL: {
    required: {
      value: true,
      message: 'This field is required.',
    },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Please enter a valid email address.',
    },
  },
  USER_NAME_STRING: {
    pattern: {
      value: /^[a-z0-9_]+$/,
      message:
        'Username allows only small alphabetic characters, numbers and underscore.',
    },
  },
  OPTIONAL_URL: {
    pattern: {
      value:
        /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/,
      message: 'Please enter a valid url.',
    },
  },
  REQUIRED_NUMBER: {
    required: {
      value: true,
      message: 'This field is required.',
    },
    pattern: {
      value: /^[0-9]+$/,
      message: 'Please enter numbers only.',
    },
  },
  OPTIONAL_NUMBER: {
    pattern: {
      value: /^[0-9]+$/,
      message: 'Please enter numbers only.',
    },
  },
};

export const maxLengthValidation = (value) => ({
  maxLength: {
    value,
    message: `This field must be less than or equal to ${value} characters.`,
  },
});

export const minLengthValidation = (value) => ({
  minLength: {
    value,
    message: `This field must be greater than or equal to ${value} characters.`,
  },
});

export default rules;
