import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  InputAdornment,
  Grid,
  Box,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
  minLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import Status from '../form-elements/status.component';
import {
  viewDataById,
  createNewData,
  updateDataById,
} from '../../services/subscribers.service';
import AllowCommentType from '../form-elements/allow-comment-type.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditSubscriber = ({ title, user, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      contact: '',
      password: '',
      status: null,
      allowPostComment: null,
      notes: '',
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    createNewData(payload)
      .then(() => {
        onSuccess('New subscriber created successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateDataById(dataId, payload)
      .then(() => {
        onSuccess('Subscriber details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      contact: data.contact,
      status: Number(data.status),
      allowPostComment: data.allowPostComment,
      notes: data.notes,
    };
    if (dataId) {
      payload.status = Number(data.status);

      updateExistingData(payload);
    } else {
      payload.password = data.password;

      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewDataById(dataId)
      .then((res) => {
        // Fill form values
        setValue('firstName', res.data?.firstName || '');
        setValue('lastName', res.data?.lastName || '');
        setValue('email', res.data?.email || '');
        setValue('contact', res.data?.contact || '');
        setValue('status', res.data?.status || 0);
        setValue('allowPostComment', res.data?.allowPostComment || 0);
        setValue('notes', res.data?.notes || '');

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle
        id="view-dialog-title"
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        {title}
        {!!dataId && user.profilePic && (
          <Box
            component="img"
            sx={{
              height: 30,
              width: 30,
              objectFit: 'cover',
              borderRadius: 15,
              display: 'inline',
              marginX: 1,
            }}
            src={user.profilePic}
          />
        )}
        {!!dataId && user.email}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="firstName"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="First Name"
                      type="text"
                      error={!!errors.firstName}
                      helperText={errors?.firstName?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="lastName"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Last Name"
                      type="text"
                      error={!!errors.lastName}
                      helperText={errors?.lastName?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="email"
                  control={control}
                  rules={validationRules.REQUIRED_EMAIL}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Email"
                      type="text"
                      error={!!errors.email}
                      helperText={errors?.email?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="contact"
                  control={control}
                  rules={validationRules.OPTIONAL_NUMBER}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Contact"
                      type="text"
                      error={!!errors.contact}
                      helperText={errors?.contact?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
              {dataId === 0 && (
                <Grid item xs={12} sm={6} md={6}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      ...validationRules.REQUIRED,
                      ...validationRules.PASSWORD,
                      ...minLengthValidation(8)
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Password"
                        type={showPassword ? 'text' : 'password'}
                        error={!!errors.password}
                        helperText={errors?.password?.message || null}
                        fullWidth
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                <Iconify
                                  icon={
                                    showPassword
                                      ? 'eva:eye-fill'
                                      : 'eva:eye-off-fill'
                                  }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                </Grid>
              )}

              {dataId === 0 && <Grid item xs={12} sm={6} md={6} />}

              <Grid item xs={12} sm={6} md={6}>
                {dataId === 0 && (
                  <Controller
                    name="status"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Status
                        id="sub_status"
                        name="sub_status"
                        label="Status"
                        defaultValue={value}
                        onChange={onChange}
                        validOptions={[0, 1]}
                        sx={{ width: '100%' }}
                        error={errors?.status?.message || ''}
                      />
                    )}
                  />
                )}
                {dataId !== 0 && (
                  <Controller
                    name="status"
                    control={control}
                    rules={validationRules.REQUIRED}
                    render={({ field: { onChange, value } }) => (
                      <Status
                        id="sub_status"
                        name="sub_status"
                        label="Status"
                        defaultValue={value}
                        onChange={onChange}
                        validOptions={[0, 1, 2]}
                        sx={{ width: '100%' }}
                        error={errors?.status?.message || ''}
                      />
                    )}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="allowPostComment"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <AllowCommentType
                      id="sub_post_comment"
                      name="sub_post_comment"
                      label="Allow to post comment (as per subscription)"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[0, 1]}
                      sx={{ width: '100%' }}
                      error={errors?.allowPostComment?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="notes"
                  control={control}
                  rules={{ ...maxLengthValidation(200) }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Notes"
                      type="text"
                      error={!!errors.notes}
                      helperText={errors?.notes?.message || null}
                      fullWidth
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditSubscriber.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.object,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};
AddEditSubscriber.defaultProps = {
  user: {},
};

export default AddEditSubscriber;
