import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS, CONTENT_ALIAS } from '../const.config';

// Categories module configurations
export const LISTING_COLUMNS = [
  {
    id: 'poster',
    label: 'Poster Preview',
    width: '10%',
    align: 'left',
    dataKey: 'poster',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'name',
    label: 'Title',
    width: '20%',
    align: 'left',
    dataKey: 'name',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'groupName',
    label: CONTENT_ALIAS.GROUP_SINGULAR,
    width: '10%',
    align: 'left',
    dataKey: 'groupName',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sku',
    label: `SKU`,
    width: '20%',
    align: 'left',
    dataKey: 'sku',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'instructor',
    label: 'Instructor',
    width: '10%',
    align: 'left',
    dataKey: 'instructor',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'isPaid',
    label: `${CONTENT_ALIAS.CATEGORY_SINGULAR} Type`,
    width: '20%',
    align: 'left',
    dataKey: 'isPaid',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    width: '10%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'isFeatured',
    label: 'Featured',
    width: '10%',
    align: 'center',
    dataKey: 'isFeatured',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.TRANSLATE.value,
    label: ACTIONS.TRANSLATE.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_POSTER.value,
    label: ACTIONS.UPLOAD_POSTER.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_CATEGORIES_COLUMNS = [
  {
    id: 'name',
    label: 'Title',
    dataKey: 'name',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'groupName',
    label: 'Group',
    dataKey: 'groupName',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'sku',
    label: `${CONTENT_ALIAS.CATEGORY_SINGULAR} SKU`,
    dataKey: 'sku',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'instructor',
    label: 'Instructor',
    dataKey: 'instructor',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'programType',
    label: `${CONTENT_ALIAS.CATEGORY_SINGULAR} Type`,
    dataKey: 'programType',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'purchaseURL',
    label: 'Purchase URL',
    dataKey: 'purchaseURL',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
