import { CONTENT_ALIAS } from './const.config';

// Define page-title configurations
const PAGE_TITLE_LIST = {
  OVERVIEW_DASHBOARD: 'Overview Dashboard',
  REALTIME_DASHBOARD: 'Live Users (Per Platform)',
  SUBSCRIBERS: 'Subscribers',
  SUBSCRIPTIONS: 'Subscriptions',
  ADMIN_USERS: 'Users',
  CONTENT_GROUP: `Content / ${CONTENT_ALIAS.GROUP_PLURAL}`,
  CONTENT_CATEGORY: `Content / ${CONTENT_ALIAS.CATEGORY_PLURAL}`,
  CONTENT_VIDEOS: `Content / ${CONTENT_ALIAS.VIDEO_PLURAL}`,
  CONTENT_TRANSMISSION_ORGANS: `Content / ${CONTENT_ALIAS.ASPECT_PLURAL}`,
  CONTENT_ADS: 'Content / Ads',
  CONTENT_SEQUENCE: 'Content / Sequence',
  CONTENT_SEQUENCE_VIDEOS: `Content / Sequence ${CONTENT_ALIAS.VIDEO_PLURAL}`,
  CONTENT_SEQUENCE_TESTIMONIAL_TABS: 'Content / Sequence Testimonial Tabs',
  CONTENT_SEQUENCE_TESTIMONIAL_CATEGORIES:
    'Content / Sequence Testimonial Categories',
  CONTENT_TABS: 'Content / Tabs',
  CONTENT_TESTIMONIALS: 'Content / Testimonials',
  CONTENT_TESTIMONIALS_TABS: 'Content / Testimonial Tabs',
  CONTENT_TESTIMONIALS_CATEGORIES: 'Content / Testimonial Categories',
  CONTENT_TESTIMONIALS_TEXT: 'Content / Text Testimonials',
  CONTENT_VIDEO_COMMENTS: `Content / ${CONTENT_ALIAS.VIDEO_SINGULAR} Comments`,
  CONTENT_EVENTS: `Content / Events`,
  NOTIFICATIONS: 'Notifications',
  SALUTATION: 'Salutation',
  REPORTS_OVERVIEW_VIDEOS: `Analytics Reports / Video Watch Overview`,
  REPORTS_LIVE_USERS: 'Analytics Reports / Live Users',
  REPORTS_SESSIONS: 'Analytics Reports / Watch Sessions',
  REPORTS_SUBSCRIPTIONS: 'Analytics Reports / Subscriptions Summary',
  LOGIN: 'Login',
  LOGOUT: 'Logout',
  ERROR_NOT_FOUND: '404 Not Found',
};

export default PAGE_TITLE_LIST;
