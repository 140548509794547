import CELL_DATA_TYPES from '../cell-types.config';
import { ACTIONS } from '../const.config';

// Videos module configurations
export const LISTING_COLUMNS = [
  {
    id: 'poster',
    label: 'Poster',
    width: '5%',
    align: 'center',
    dataKey: 'poster',
    sortable: false,
    type: CELL_DATA_TYPES.IMAGE,
  },
  {
    id: 'title',
    label: 'Title',
    width: '20%',
    align: 'left',
    dataKey: 'title',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Type',
    width: '10%',
    align: 'left',
    dataKey: 'type',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'instructor',
    label: 'Instructor',
    width: '10%',
    align: 'left',
    dataKey: 'instructor',
    sortable: true,
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'eventStartedAt',
    label: 'Start Time',
    width: '15%',
    align: 'center',
    dataKey: 'eventStartedAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'eventEndedAt',
    label: 'End Time',
    width: '15%',
    align: 'center',
    dataKey: 'eventEndedAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'status',
    label: 'Status',
    width: '5%',
    align: 'center',
    dataKey: 'status',
    sortable: true,
    type: CELL_DATA_TYPES.SWITCH,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    width: '15%',
    align: 'right',
    dataKey: 'createdAt',
    sortable: true,
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];

export const LISTING_ACTIONS = [
  {
    action: ACTIONS.EDIT.value,
    label: ACTIONS.EDIT.label,
  },
  {
    action: ACTIONS.VIEW.value,
    label: ACTIONS.VIEW.label,
  },
  {
    action: ACTIONS.UPLOAD_POSTER.value,
    label: ACTIONS.UPLOAD_POSTER.label,
  },
  {
    action: ACTIONS.TRANSLATE.value,
    label: ACTIONS.TRANSLATE.label,
  },
  {
    action: ACTIONS.DELETE.value,
    label: ACTIONS.DELETE.label,
  },
];

export const VIEW_VIDEOS_COLUMNS = [
  {
    id: 'title',
    label: 'Title',
    dataKey: 'title',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'description',
    label: 'Description',
    dataKey: 'description',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'type',
    label: 'Type',
    dataKey: 'type',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'instructor',
    label: 'Instructor',
    dataKey: 'instructor',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'isExclusive',
    label: 'Exclusive Event',
    dataKey: 'isExclusive',
    type: CELL_DATA_TYPES.BOOL,
  },
  {
    id: 'eventStartedAt',
    label: 'Start Time',
    dataKey: 'eventStartedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'eventEndedAt',
    label: 'End Time',
    dataKey: 'eventEndedAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
  {
    id: 'poster',
    label: 'Poster URL',
    dataKey: 'poster',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'registerLink',
    label: 'Register URL',
    dataKey: 'registerLink',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'joinLink',
    label: 'Join URL',
    dataKey: 'joinLink',
    type: CELL_DATA_TYPES.TEXT,
  },
  {
    id: 'status',
    label: 'Status',
    dataKey: 'status',
    type: CELL_DATA_TYPES.STATUS,
  },
  {
    id: 'createdAt',
    label: 'Created Date',
    dataKey: 'createdAt',
    type: CELL_DATA_TYPES.TIMESTAMP,
  },
];
