import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewTestimonialCategory,
  viewTestimonialCategoryById,
  updateTestimonialCategoryById,
} from '../../services/content.service';
import Status from '../form-elements/status.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditTestimonialCategory = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      name: '',
      description: '',
      sequence: '',
      status: null,
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    createNewTestimonialCategory(payload)
      .then(() => {
        onSuccess('Testimonial Category added successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateTestimonialCategoryById(dataId, payload)
      .then(() => {
        onSuccess('Testimonial Category details updated successfully.');
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const payload = {
      name: data.name,
      sequence: data.sequence,
      description: data.description,
      status: data.status,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewTestimonialCategoryById(dataId)
      .then((res) => {
        // Fill form values
        setValue('name', res.data?.name || '');
        setValue('sequence', res.data?.sequence?.toString() || '');
        setValue('description', res.data?.description || '');
        setValue('status', res.data?.status || 0);

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="name"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(255) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Category Name"
                      type="text"
                      error={!!errors.name}
                      helperText={errors?.name?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="sequence"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(3) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Category Sequence"
                      type="text"
                      error={!!errors.sequence}
                      helperText={errors?.sequence?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 3 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(1500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                      multiline
                      rows={3}
                      inputProps={{ maxLength: 1500 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="sub_status"
                      name="sub_status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[1, 0]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditTestimonialCategory.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default AddEditTestimonialCategory;
