import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress } from '@mui/material';

const Loading = ({ minHeight, display }) => (
  <Box
    sx={{
      display,
      alignItems: 'center',
      justifyContent: 'center',
      minHeight,
      height: '100%',
    }}
  >
    <CircularProgress disableShrink />
  </Box>
);

Loading.propTypes = {
  minHeight: PropTypes.number,
  display: PropTypes.string,
};
Loading.defaultProps = {
  minHeight: 400,
  display: 'flex',
};

export default Loading;
