import React, { useEffect, useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
  Slide,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  TextField,
  Grid,
  Switch,
  FormControlLabel,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import validationRules, {
  maxLengthValidation,
} from '../../utils/validations.util';
import Iconify from '../common/iconify.component';
import Loading from '../table-elements/loading.component';
import SnackbarInfo from '../common/snackbar-info.component';
import {
  createNewEvent,
  updateEventById,
  viewEventById,
} from '../../services/content.service';
import Status from '../form-elements/status.component';
import CalendarEventTypes from '../form-elements/calendar-event-types.component';
import Datetimepicker from '../form-elements/datetimepicker.component';

const Transition = forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

const AddEditCalendarEvents = ({ title, dataId, onCancel, onSuccess }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      type: '',
      status: null,
      instructor: '',
      exclusive: 0,
      eventStartTime: null,
      eventEndTime: null,
      poster: '',
      registerUrl: '',
      joinUrl: '',
    },
  });

  const [loading, setLoading] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({
    show: false,
    type: '',
    message: '',
  });

  const saveNewData = (payload) => {
    createNewEvent(payload)
      .then((res) => {
        if (res.data.success) {
          onSuccess('New event added successfully.');
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const updateExistingData = (payload) => {
    updateEventById(dataId, payload)
      .then((res) => {
        if (res.data.success) {
          onSuccess('Event details updated successfully.');
        }
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setFormSubmitted(false);
      });
  };

  const onFormSubmit = (data) => {
    setFormSubmitted(true);
    setSnackbarInfo({
      ...snackbarInfo,
      show: false,
    });

    const eventStartTimeGMT =
      Date.parse(data.eventStartTime?.$d.toISOString()) / 1000;
    const eventEndTimeGMT =
      Date.parse(data.eventEndTime?.$d.toISOString()) / 1000;

    if (eventStartTimeGMT >= eventEndTimeGMT) {
      setTimeout(() => {
        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message: 'Event end time must be greater than event start time.',
        });
      }, 100);

      setFormSubmitted(false);
      return;
    }

    const payload = {
      title: data.title,
      description: data.description,
      type: data.type,
      status: data.status,
      instructor: data.instructor,
      isExclusive: data.exclusive ? 1 : 0,
      eventStartTime: eventStartTimeGMT,
      eventEndTime: eventEndTimeGMT,
      poster: data.poster,
      registerUrl: data.registerUrl,
      joinUrl: data.joinUrl,
    };
    if (dataId) {
      updateExistingData(payload);
    } else {
      saveNewData(payload);
    }
  };

  useEffect(() => {
    if (!dataId) {
      setLoading(false);
      return;
    }

    viewEventById(dataId)
      .then((res) => {
        // Fill form values
        setValue('title', res.data?.title || '');
        setValue('description', res.data?.description || '');
        setValue('type', res.data?.type || null);
        setValue('status', res.data?.status || 0);
        setValue('instructor', res.data?.instructor || null);
        setValue('exclusive', res.data?.isExclusive || 0);
        setValue('eventStartTime', dayjs.unix(res.data?.eventStartedAt) || 0);
        setValue('eventEndTime', dayjs.unix(res.data?.eventEndedAt) || '');
        setValue('poster', res.data?.poster || '');
        setValue('registerUrl', res.data?.registerLink || '');
        setValue('joinUrl', res.data?.joinLink || '');

        setLoading(false);
      })
      .catch((err) => {
        const message =
          err.response?.data?.message ||
          'Something went wrong, please try again.';

        setSnackbarInfo({
          ...snackbarInfo,
          show: true,
          type: 'error',
          message,
        });
        setLoading(false);
      });
  }, [dataId]);

  return (
    <Dialog
      open
      aria-labelledby="add-dialog-title"
      aria-describedby="add-dialog-description"
      TransitionComponent={Transition}
      fullWidth
      maxWidth="lg"
    >
      <DialogTitle id="view-dialog-title">{title}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onCancel}
        size="small"
        sx={{
          position: 'absolute',
          right: 10,
          top: 15,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Iconify icon="ic:outline-close" />
      </IconButton>

      <DialogContent dividers id="add-dialog-description">
        {loading && <Loading />}

        {!loading && (
          <form id="add-edit-form" onSubmit={handleSubmit(onFormSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="title"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(100) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Event Title"
                      type="text"
                      error={!!errors.title}
                      helperText={errors?.title?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 255 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="description"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(1500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Description"
                      type="text"
                      error={!!errors.description}
                      helperText={errors?.description?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="type"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <CalendarEventTypes
                      id="type"
                      name="type"
                      label="Event Type"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={['WEBINAR', 'COMMUNITY_GATHERING']}
                      sx={{ width: '100%' }}
                      error={errors?.type?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="status"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Status
                      id="status"
                      name="status"
                      label="Status"
                      defaultValue={value}
                      onChange={onChange}
                      validOptions={[0, 1]}
                      sx={{ width: '100%' }}
                      error={errors?.status?.message || ''}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="instructor"
                  control={control}
                  rules={{
                    ...validationRules.REQUIRED,
                    ...{ ...maxLengthValidation(100) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Instructor"
                      type="text"
                      error={!!errors.instructor}
                      helperText={errors?.instructor?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="exclusive"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <FormControlLabel
                      control={<Switch checked={value} onChange={onChange} />}
                      label="Exclusive Event"
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="eventStartTime"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Datetimepicker
                      id="eventStartTime"
                      name="eventStartTime"
                      label="Event Start Time"
                      value={value}
                      defaultValue={!dataId ? null : value}
                      onChange={onChange}
                      error={errors?.eventStartTime?.message || ''}
                      disablePast
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="eventEndTime"
                  control={control}
                  rules={validationRules.REQUIRED}
                  render={({ field: { onChange, value } }) => (
                    <Datetimepicker
                      id="eventEndTime"
                      name="eventEndTime"
                      label="Event End Time"
                      value={value}
                      defaultValue={!dataId ? null : value}
                      onChange={onChange}
                      error={errors?.eventEndTime?.message || ''}
                      disablePast
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <Controller
                  name="poster"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Poster URL"
                      type="text"
                      error={!!errors.poster}
                      helperText={errors?.poster?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="registerUrl"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Register URL"
                      type="text"
                      error={!!errors.registerUrl}
                      helperText={errors?.registerUrl?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 500 }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <Controller
                  name="joinUrl"
                  control={control}
                  rules={{
                    ...validationRules.OPTIONAL_URL,
                    ...{ ...maxLengthValidation(500) },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Join URL"
                      type="text"
                      error={!!errors.joinUrl}
                      helperText={errors?.joinUrl?.message || null}
                      fullWidth
                      inputProps={{ maxLength: 250 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </form>
        )}
      </DialogContent>

      <DialogActions sx={{ justifyContent: 'left', marginLeft: 2 }}>
        <Button
          type="submit"
          form="add-edit-form"
          color="primary"
          variant="contained"
          disabled={loading || formSubmitted}
        >
          Save
        </Button>
        <Button
          color="secondary"
          variant="contained"
          onClick={onCancel}
          disabled={formSubmitted}
        >
          Cancel
        </Button>
      </DialogActions>

      {snackbarInfo.show && (
        <SnackbarInfo type={snackbarInfo.type} message={snackbarInfo.message} />
      )}
    </Dialog>
  );
};

AddEditCalendarEvents.propTypes = {
  title: PropTypes.string.isRequired,
  dataId: PropTypes.number.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  options: PropTypes.object.isRequired,
};

export default AddEditCalendarEvents;
